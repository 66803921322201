import { CONTENT_WIDTH, DEFAULT_ORANGE_CODE } from "../../utils/constants";
import TextBookletBlack from "./TextBookletBlack";
import Read from "./Read";
import IfYouHaveDisciplineLevelXSandbox from "./IfYouHaveDisciplineLevelXSandbox";
import MakeAttributeSkillCheckDifficultyX from "./MakeAttributeSkillCheckDifficultyX";
import CombatBeginsGoldtext from "./CombatBeginsGoldtext";
import IfYouHaveClueTokenXSandbox from "./IfYouHaveClueTokenXSandbox";
import IfYourCharacterHasDamageSandbox from "./IfYourCharacterHasDamageSandbox";
import TextBoxContainer from "./TextBoxContainer";
import IfYouWinLoseSandbox from "./IfYouWinLoseSandbox";
import IfYouLoseYouFailBlackboxMultitext from "./IfYouLoseYouFailBlackboxMultitext";
import TableOfContentSectionPage from "./TableOfContentSectionPage";
import TableOfContentsItemPage from "./TableOfContentsItemPage";
import IfYouFailSandbox from "./IfYouFailSandbox";
import MakeARouseCheckSandbox from "./MakeARouseCheckSandbox";
import TextBookletGeneral from "./TextBookletGeneral";
import MakeAttributeSkillCheckBlackbox from "./MakeAttributeSkillCheckBlackbox";
import RuleTextColorBox from "./RuleTextColorBox";
import TextBoxModuleContainer from "./TextBoxModuleContainer";
import TextOnlyModule from "./TextOnlyModule";
import SetupImageHexGrid from "./SetupImageHexGrid";
import TextBoxModuleContainerV2 from "./TextBoxModuleContainerV2";
import IfYouHaveDisciplineSandbox from "./IfYouHaveDisciplineSandbox";
import IfYouHaveTheXItemCardSandbox from "./IfYouHaveTheXItemCardSandbox";
import StaticMakeAAttributeSkillCheckWith2OutcomesBlackbox from "./StaticMakeAAttributeSkillCheckWith2OutcomesBlackbox";
import TextBoxImageContainer from "./TextBoxImageContainer";
import GangrelRuleBoxWithTable from "./GangrelRuleBoxWithTable";
import MakeAAttributeSkillCheckDifficultyXLongVersionSandbox from "./MakeAAttributeSkillCheckDifficultyXLongVersionSandbox";
import SetupNpcToken from "./SetupNpcToken";
import SetupPlayerToken from "./SetupPlayerToken";
import IfYouAreCharacterSandbox from "./IfYouAreCharacterSandbox";
import StaticMakeAttributeSkillCheckDifficultyXSandbox from "./StaticMakeAttributeSkillCheckDifficultyXSandbox";
import SetupNpcColumn from "./SetupNpcColumn";
import IfNpc1Npc2Npc3AreOnTheTile from "./IfNpc1Npc2Npc3AreOnTheTile";
import IfTheTrackertypeTrackerReachesX from "./IfTheTrackertypeTrackerReachesX";
import IfNpcisAdjacenttoYouChapterFailBlackboxMultitext from "./IfNpcisAdjacenttoYouChapterFailBlackboxMultitext";
import GoldTextBox from "./GoldTextBox";
import FeDirective from "./FeDirective";
import TextBoxContainerContainer from "./TextBoxContainerContainer";
import TextBoxContainerContainerContainer from "./TextBoxContainerContainerContainer";
import TextBoxContainerContainerIconText from "./TextBoxContainerContainerIconText";
import FeedingEventPhysicalOrMentalResult from "./FeedingEventPhysicalOrMentalResult";
import IfYouAreSamuelArmstrongOrHaroldBeaulieuSandbox from "./IfYouAreSamuelArmstrongOrHaroldBeaulieuSandbox";
import IfYouHavetheXItemCardChapterFailBlackboxMultitext from "./IfYouHavetheXItemCardChapterFailBlackboxMultitext";
import IfYouHaveXHungerSandbox from "./IfYouHaveXHungerSandbox";
import RollaRedDieSandbox from "./RollaRedDieSandbox";
import IfYouHaveDisciplineLevelXSandboxV2 from "./IfYouHaveDisciplineLevelXSandboxV2";
import IfYouHaveTheSkillLevelXSandbox from "./IfYouHaveTheSkillLevelXSandbox";
import IfThereAreNoPlayerOnTheTileYouFailBlackbox from "./IfThereAreNoPlayerOnTheTileYouFailBlackbox";
import TemplateOtherwiseResumePlayingBlackText from "./TemplateOtherwiseResumePlayingBlackText";
import IfYouAreTheOnlyPlayerOnTheTileYouFailBlackboxMultitext from "./IfYouAreTheOnlyPlayerOnTheTileYouFailBlackboxMultitext";
import MakeSkillChecksBelowCanBeAttemptedOnlyOnceSandboxBigBox from "./MakeSkillChecksBelowCanBeAttemptedOnlyOnceSandboxBigBox";
import YouMustEachMakeAAttributeSkillCheckDifficultyXSandbox from "./YouMustEachMakeAAttributeSkillCheckDifficultyXSandbox";
import IfYouDoNotHaveClueTokenXSandbox from "./IfYouDoNotHaveClueTokenXSandbox";
import IfYouAreCharacterIsNotActiveSandbox from "./IfYouAreCharacterIsNotActiveSandbox";
import TemplateRollaRedDieSuccessOnlySandbox from "./TemplateRollaRedDieSuccessOnlySandbox";
import MakeAAttributeSkillCheckDifficultyXWithRuleGrayBox from "./MakeAAttributeSkillCheckDifficultyXWithRuleGrayBox";
import IfYouHaveDisciplineLevelXDoNotHaveEffectCardSandbox from "./IfYouHaveDisciplineLevelXDoNotHaveEffectCardSandbox";
import HavetheEffectCardChapterFailBlackboxMultitext from "./HavetheEffectCardChapterFailBlackboxMultitext";
import IfYouHaveDisciplineLevel3Sandbox from "./IfYouHaveDisciplineLevel3Sandbox";
import IfYouHaveClueTokenXAndYouHaveDisciplineXLevelXSandbox from "./IfYouHaveClueTokenXAndYouHaveDisciplineXLevelXSandbox";
import IfYouAreCharacterAndYouHaveItemCardSandbox from "./IfYouAreCharacterAndYouHaveItemCardSandbox";
import Chapter16TableBlackbox from "./Chapter16TableBlackbox";
import EachCharacterWithoutatLeastXMustTakeTheEffectCard from "./EachCharacterWithoutatLeastXMustTakeTheEffectCard";
import TextWithBulletPoint from "./TextWithBulletPoint";
import IfAnotherCharacterEndsTheirTurnOnAnHexAdjacentToYou from "./IfAnotherCharacterEndsTheirTurnOnAnHexAdjacentToYou";
import TextBoxContainerFullWidth from "./TextBoxContainerFullWidth";
import IfAnotherCharacterTurnOnAnHexAdjacentToYouSandbox from "./IfAnotherCharacterTurnOnAnHexAdjacentToYouSandbox";
import RollARedDieFailOnlySandbox from "./RollARedDieFailOnlySandbox";
import YouMustEachMakeAttributeSkillSandbox from "./YouMustEachMakeAttributeSkillSandbox";    
import YouMustEachMakeAttributeSkillCheckTableDifficulty from "./YouMustEachMakeAttributeSkillCheckTableDifficulty";
import MustMakeAttributeSkillCheckDifficultyXLongVersion from "./MustMakeAttributeSkillCheckDifficultyXLongVersion";
import IfBothCharacterAreInvolvedInTheSequence from "./IfBothCharacterAreInvolvedInTheSequence";
import IfYouWinOrIfYouLoseSandbox from "./IfYouWinOrIfYouLoseSandbox";
import IfYouHaveDiscipline1OrDiscipline2LevelX from "./IfYouHaveDiscipline1OrDiscipline2LevelX";
import OtherwiseResumePlayingCreamText from "./OtherwiseResumePlayingCreamText";
import IfAllActionTokensHaveBeenRemovedFromTheTileChapterFail from "./IfAllActionTokensHaveBeenRemovedFromTheTileChapterFail";
import IfYouHaveTheXEffectCardChapterFailBlackbox from "./IfYouHaveTheXEffectCardChapterFailBlackbox";
import IfYouHaveUsedBiteCombatCardOrAnyDiscipline from"./IfYouHaveUsedBiteCombatCardOrAnyDiscipline";
import IfYouHaveDisciplineLevelXAndThereAreNoOtherNpcs from "./IfYouHaveDisciplineLevelXAndThereAreNoOtherNpcs";
import EachPlayerMustMakeRouseCheckFailureOnly from"./EachPlayerMustMakeRouseCheckFailureOnly";
import IfYouHaveTheFollowingEffectCards from "./IfYouHaveTheFollowingEffectCards";
import TemplateMakARouseCheckForEachDifficult from "./TemplateMakARouseCheckForEachDifficult";
import IfYouAreArenKonwayOrThomasChartrand from "./IfYouAreArenKonwayOrThomasChartrand";
import IfYouHaveUsedARangedWeaponOrItemCards from "./IfYouHaveUsedARangedWeaponOrItemCards";
import MakeARouseCheck from "./MakeARouseCheck";
import MakeARouseCheckWithBoxesSandbox from "./MakeARouseCheckWithBoxesSandbox";
import IfYouHaveRangedWeaponItemCardAndEitherDisciplineLevelX from "./IfYouHaveRangedWeaponItemCardAndEitherDisciplineLevelX";
import TemplateTheItemsAreWXYZ from "./TemplateTheItemsAreWXYZ";
import YouDoNotHaveClueTokenXAndNpcOnTileSandbox from "./YouDoNotHaveClueTokenXAndNpcOnTileSandbox";
import IfYouHaveDisciplineLevelXOrTheSkillLevelYSandbox from "./IfYouHaveDisciplineLevelXOrTheSkillLevelYSandbox"
import TemplateIfYouHadDiscardedWeaponsMultibox from "./TemplateIfYouHadDiscardedWeaponsMultibox";
import TextBookletGeneralV2 from "./TextBookletGeneralV2";
import ConclusionTextBlock from "./ConclusionTextBlock";
import TextBoxContainerLeftFullWidth from "./TextBoxContainerLeftFullWidth";
import SetupNpcTokenV2 from "./SetupNpcTokenV2";
import TemplateSetupPreyNpcSection from "./TemplateSetupPreyNpcSection";
import TemplateSetupPreyNpcSectionV2 from "./TemplateSetupPreyNpcSectionV2";
import StorybookConclusionNoRecoveryBetweenChapters from "./StorybookConclusionNoRecoveryBetweenChapters";
import TemplateStorybookConclusionFromThisPointOnSandbox from "./TemplateStorybookConclusionFromThisPointOnSandbox";
import TemplateStorybookConclusionExpansionAvailable from "./TemplateStorybookConclusionExpansionAvailable";
import TemplateInterludeTextBlack from "./TemplateInterludeTextBlack";
import TemplateInterludeTimeheaderVersion from "./TemplateInterludeTimeheaderVersion";
import TemplateStorybookConclusion from "./TemplateStorybookConclusion";
import MakeThe5SkillChecksBelowEachCheckCanBeAttemptedOnlyOnce from './MakeThe5SkillChecksBelowEachCheckCanBeAttemptedOnlyOnce';
import TextWithModule from './TextWithModule';
import IfYouHaveClueTokenXAndActionTokenXIsNotOnTheTileChapterFailBlackbox from "./IfYouHaveClueTokenXAndActionTokenXIsNotOnTheTileChapterFailBlackbox";
import IfYouHaveDisciplineLevelXSandboxV3 from "./IfYouHaveDisciplineLevelXSandboxV3";
import TemplateStorybookConclusionSideQuestAvailable from "./TemplateStorybookConclusionSideQuestAvailable";
import IfNpc1IsOnTheTileStorybook from "./IfNpc1IsOnTheTileStorybook";
import IfYouAreLuciannaRicciOrJadeLinhDuplessis from "./IfYouAreLuciannaRicciOrJadeLinhDuplessis";
import PatrolToken from "./PatrolToken";
import PatrolArrowRight from "./PatrolArrowRight";
import TextBoxModuleContainerV3 from "./TextBoxModuleContainerV3";
import TextBoxBulletContainer from "./TextBoxBulletContainer";
import ImageInterlude from "./ImageInterlude";
import InterludeBlackBoxContainer from "./InterludeBlackBoxContainer";
import TextOnlyBlock from "./TextOnlyBlock";
import TextBoxDoubleContainerContainerContainer from "./TextBoxDoubleContainerContainerContainer";
import InterludeSandboxSection from "./InterludeSandboxSection";
import InterludeSandboxSectionTriple from "./InterludeSandboxSectionTriple";
import StorybookConclusion from "./StorybookConclusion";
import MakeRouseChecks from "./MakeRouseChecks";
import IfYouWinAnYouUsedAnyOfTheFollowingItenCards from "./IfYouWinAnYouUsedAnyOfTheFollowingItenCards";
import IfYouWantToLeaveYouCanDoSo from "./IfYouWantToLeaveYouCanDoSo";
import StorybookIntroTextCalebLetter from "./StorybookIntroTextCalebLetter";
import TemplateTextGeneral from "./TemplateTextGeneral";
import TemplateTextGeneral2 from "./TemplateTextGeneral2";
import YouMustEachMakeAttributeSkillCheck from "./YouMustEachMakeAttributeSkillCheck";
import MustEachMakeAttributeSkillCheckDifficultyX from "./MustEachMakeAttributeSkillCheckDifficultyX";
import TemplateStorybookConclusionNoDiscardClueTokens from "./TemplateStorybookConclusionNoDiscardClueTokens";
import TemplateInterludeSubSection from "./TemplateInterludeSubSection";
import TemplatePatrolEllipsis from "./TemplatePatrolEllipsis";
import XxxStorybookConclusionNoDiscardClueTokens from "./XxxStorybookConclusionNoDiscardClueTokens";
import XxxRuleGrayChap14Restart from "./XxxRuleGrayChap14Restart";

const ModuleSelector = ({
  index,
  variable,
  cards,
  card,
  deckId,
  fullWidth,
  templateData,
  separatorColor,
  noStyle,
  isPhysical,
  removeLink
}) => {
  const ctaId = variable.ctaId;
  const variables = variable.variablesValues;
  const template = templateData.ctas.find((_) => _._id === ctaId);
  const type = variable.type;

  if (type === "separator") {

    const templateIds = [
      "65300455779575d19d077e5c",//chap tremere intro
      "65313228779575d19d098720",//chap 1 intro
      "6531379a779575d19d09aa57",//chap 2 intro
      '65313f05779575d19d09e6e0',//chap 5 intro
      '65318a46779575d19d0b95e7',//chap 9 intro
      "65381d75caf6418cb0f51260",//chap 11 intro
      '65382454caf6418cb0f54322',//chap 12 intro
      '65395c55caf6418cb0f6b8d3',//chap 13 intro
      '66c3d21e39261b501da55b76',//chap 14 intro
      '653963dfcaf6418cb0f6df2e',//chap 16 intro
      '66c3d22339261b501da55b8f',//chap 18 intro
      '65396704caf6418cb0f6fb3a',//chap 19 intro
      '654e92a0451d7e555ca28dd2',//chap 20 intro
      '654e9834451d7e555ca529ae',//chap 21 intro 
      '6565f914b7d785d3ba690047',//chap 26 intro
      '65665be9b7d785d3ba789ce8',//chap 33 intro
      '6568fcfbb7d785d3ba9d43b7',//chap 34 intro
      '6568fe18b7d785d3ba9d47aa',//chap 35 intro
      '656a528b89231f88c3f156b6',//chap Hecata 1 intro
      '656df6ed89231f88c3051234',//Ministry 1 intro
      '6569fd4089231f88c3da696a',//Banu haquim 1 intro
      '656a3b7789231f88c3e804dd',//Banu haquim 2 intro
      '65690c60b7d785d3ba9ef8fe',//Masquera intro 2
      '65690716b7d785d3ba9e06df',//Ancien path intro 1
      '6569f32c89231f88c3d7ea9f',//Obscur Path 1 intro
      '65396382caf6418cb0f6dd2e',//Chapitre 14 intro
      '65396437caf6418cb0f6e132',//Chapitre 18 intro
    ];

    return (
      <div
        className="relative line-sectionjustify-end my-3"
        key={"separator-" + index}
        style={{
          display: templateIds.includes(card._id) ? 'none' : 'block',
          backgroundColor: separatorColor
            ? separatorColor
            : DEFAULT_ORANGE_CODE,
          height: "1px",
          width:fullWidth ? '100%' : CONTENT_WIDTH, 
        }}
      >
        <span style={{opacity:0}}>.</span>
      </div>
    );
  }

  if (["6526a9ca838075da23c1cfa5",    
     "6556740058c548552e36a5c3", //boxe rose chap 12 E.116
     ].indexOf(ctaId) > -1) return null;

  const textBlockContainerCtaIds = [
    "6516efcd838075da23be546b",
    "65086f7d7556c2e5db75a8a5",
    "651702b6838075da23be555c",
    "659c7a94c8178fd6047ac6e1",
    "650369e3badc531267cebb63",
    "652565e7838075da23c15790",
    "651ab3b6838075da23be9a1d",
    "651ab455838075da23be9a69",
    "651ab4db838075da23be9ae9",
    "651ab69c838075da23be9c00",
    "6517128a838075da23be5b75",
    "65087d587556c2e5db75b61e",
    "650351d4badc531267ce4d14",
    "64f74e1395cb62ffb977e5ee",
    "64f758f195cb62ffb977ecbd",
    "65035a02badc531267ce8d33",
    "650314aebadc531267ce0336",
    "650350c9badc531267ce4ae2",
    "65086f067556c2e5db75a80d",
    "6503515cbadc531267ce4bb7",
    "655b98c358c548552e5672ea",
    "6503606abadc531267ce9fcb",
    "650317e5badc531267ce0a08",
    "65132e9f838075da23bdcd91",
    "651434d2838075da23bde8fd",
    "6512f643838075da23bda13c",
    "64f7515f95cb62ffb977e990",
    "652836d5838075da23c26c1f",
    "64ff72a4590095491a93337f",
    "6553c478451d7e555cbb028c",
    "64f7571295cb62ffb977ec87",
    "65035a6fbadc531267ce8dae",
    "651adfed838075da23beb54c",
    "651ae92f838075da23beb586",
    "65201701838075da23c0da0a",
    "65493ee2b7a06f9fe8735b4e",
    "651ac41f838075da23bea272",
    "6512fa9d838075da23bda33d",
    "653acf92caf6418cb0fb5847",
    "6503601bbadc531267ce9f3f",
    "655ec5d658c548552e75bd82",
    "64ff3026590095491a92a2b4",
    "65086d417556c2e5db75a58d",
    "655ce5d558c548552e618cc7",
    "650884d4838075da23bc3eed",
    "6512f229838075da23bd9a2c",
    "65086e4f7556c2e5db75a6d4",
    "6512f432838075da23bd9b4c",
    "650d8e2e838075da23bcfb58",
    "654eae92451d7e555ca90217",
    "650879807556c2e5db75b32a",
    "650879027556c2e5db75b2d8",
    "65131fe2838075da23bdc520",
    "6501ca84badc531267cdb8ec",
    "655d1edf58c548552e65a2f6",
    "6501c96cbadc531267cdb7f7",
    "659c934dc8178fd6047c1ff6",
    "650864be7556c2e5db759bc6",
    "65086fe27556c2e5db75a8f3",
    "65132f6b838075da23bdcfbd",
    "6512fcb8838075da23bda622",
    "6537d163caf6418cb0f1fab3",
    "6531630f779575d19d0a609e",
    "65133230838075da23bdd4ab",
    "6531678d779575d19d0a831d",
    "650870907556c2e5db75a92e",
    "65087db17556c2e5db75b654",
    "6548dd31b7a06f9fe86f1642",
    "6570a5c189231f88c330c745",
    "655262b7451d7e555caf77f9",
    "6548de7bb7a06f9fe86f171a",
    "65523d95451d7e555cabad48",
    "6548e467b7a06f9fe86f1d74",
    "6548e53fb7a06f9fe86f1db9",
    "65525299451d7e555cae1202",
    "6570f74b89231f88c34125a1",
    "655d201758c548552e65d132",
    "65035ae2badc531267ce8de6",
    "650873f17556c2e5db75aec9",
    "650877bc7556c2e5db75b1cf",
    "65bace56c8178fd6046eeac7",
    "65086bc57556c2e5db75a2ea",
    "656bbcd189231f88c3f55a56",
    "64ff70e8590095491a933029",
    "65131e90838075da23bdc432",
    "65bad398c8178fd60470763a",
    "65bbfb40c8178fd60473103d",
    "65569a6658c548552e3ca246",
    "65526538451d7e555caf915c",
    "6552691d451d7e555cb00dba",
    "65526b7c451d7e555cb03b76",
    "65526ec3451d7e555cb0d01b",
    "650313bebadc531267cdffef",
    "654d31b3451d7e555c930c3c",
    "654d663d451d7e555c9465a7",
    "6514373a838075da23bdeaf5",
    "654e73aa451d7e555c99a0a4",
    "654e7ebb451d7e555c9c66b3",
    "65529fba451d7e555cb4c859",
    "654d4a23451d7e555c93e491",
    "65086e977556c2e5db75a759",
    "655d1a7a58c548552e6545fb",
    "65847697b9565bc566ac2b9d",
    "64ff3205590095491a92a4e3",
    "650881947556c2e5db75b8bc",
    "65a626f1c8178fd604a1be0b",
    "65a6277bc8178fd604a1e942",
    "6556758958c548552e36de6c",
    "654e75d5451d7e555c99c4aa",
    "65a6f74cc8178fd604a7672c",
    "657157f089231f88c34a4f24",
    "659e2d69c8178fd60480676e",
    "65aef3e5c8178fd604f54f6c",
    "655d27ff58c548552e66b1b9",
    "6554c663451d7e555cbf26d9",
    "6595c201e5509159c8a3ea32",
    "65048b024c6a15d9553e3089",
    "659d8223c8178fd6047eb1b4",
    "6596fce6e5509159c8bb88ec",
    "655664aa58c548552e356b6b",
    "655ea77858c548552e74b7f2",
    "65972d3fe5509159c8c4458d",
    "650870eb7556c2e5db75a957",
    "65972ee7e5509159c8c4723d",
    "65a73714c8178fd604ab0198",
    "6553d374451d7e555cbcfe0d",
    "6553daa2451d7e555cbd35a7",
    "650875e77556c2e5db75b0d7",
    "6553dc7c451d7e555cbd5836",
    "650360acbadc531267cea0e7",
    "6513341f838075da23bddd3d",
    "65ab0fbec8178fd604e0c2a4",
    "65ab01fac8178fd604ddf0b7",
    "65568a7758c548552e3b2a0b",
    "65ab1a77c8178fd604e301a6",
    "655665b358c548552e35b515",
    "6556688258c548552e35ded2",
    "65566ad858c548552e36175d",
    "65566d3558c548552e363eea",
    "65568ea658c548552e3bf0f9",
    "65566e5d58c548552e365356",
    "6556743f58c548552e36b82f",
    "6556660658c548552e35c724",
    "65567f3e58c548552e372093",
    "650865c87556c2e5db759ceb", 
    "655cc38858c548552e5f058e",
    "6567ca83b7d785d3ba86c0b5",
    "655b8d5958c548552e53aa56",
    "6567e3a6b7d785d3ba87034e",
    "6570fced89231f88c341de0e",
    "655d272a58c548552e66998e",
    "65711a3d89231f88c343116c",
    "655ce03958c548552e615bfc",
    "6567e52cb7d785d3ba870410",
    "6567e5ccb7d785d3ba872397",
    "6560d421e22486beedf0cdf4",
    "655ce10e58c548552e615c65",
    "655f7d7058c548552e7e7639",
    "655cdb4358c548552e60b2fe",
    "655ce1ea58c548552e617296",
    "655d03fa58c548552e648e7a",
    "654e7644451d7e555c99d3f3",
    "655e460f58c548552e6aaf67",
    "65144649838075da23bdf3cc",
    "655ce6b258c548552e618de1",
    "655ce77858c548552e618ee8",
    "655d1be958c548552e655c97",
    "655d1df458c548552e65764a",
    "6515b979838075da23be2a1a",
    "655d210858c548552e66031a",
    "655d224f58c548552e6606cb",
    "655d249658c548552e664d3a",
    "655d06ba58c548552e64bc44",
    "655d2cde58c548552e67573a",
    "655d30ff58c548552e678c24",
    "655d367458c548552e67d5c8",
    "6501ff8dbadc531267cdd582",
    "655d385158c548552e67d7fc",
    "65036a31badc531267cebce3",
    "655e296458c548552e690a3f",
    "655e26ac58c548552e68c36c",
    "655e2de058c548552e69c898",
    "655e2eb458c548552e69ca5c",
    "65660439b7d785d3ba6af2f8",
    "656604afb7d785d3ba6b2cec",
    "65660818b7d785d3ba6b6fe5",
    "65b0a3e2c8178fd6040ebb80",
    "656608c7b7d785d3ba6b8ed5",
    "65660bafb7d785d3ba6c4499",
    "65660eeab7d785d3ba6d34e5",
    "65661057b7d785d3ba6d90cc",
    "65661005b7d785d3ba6d7269",
    "6579f00589231f88c3ad2402",
    "6566111bb7d785d3ba6dcf88",
    "6515b0af838075da23be1e79",
    "6557a3e858c548552e429c31",
    "64ff59d3590095491a92f07f",
    "64ff37a4590095491a92a8c6",
    "64ff4f3b590095491a92bd68",
    "6557d16a58c548552e497675",
    "6557db2958c548552e4b44a9",
    "65661efdb7d785d3ba6f15a6",
    "65666836b7d785d3ba799b9f",
    "65662483b7d785d3ba6fea8b",
    "656628fab7d785d3ba700fd0",
    "65662df1b7d785d3ba713e87",
    "65662ef2b7d785d3ba715db4",
    "65087ee47556c2e5db75b758",
    "65663044b7d785d3ba7199ef",
    "65663517b7d785d3ba7378da",
    "6501cd6bbadc531267cdbb97",
    "6501ff22badc531267cdd534",
    "650874fd7556c2e5db75b005",
    "655f6f4058c548552e7b030a",
    "655f703658c548552e7b1db8",
    "655f70a958c548552e7b6a90",
    "6515ba86838075da23be2b3b",
    "6515dfb7838075da23be3877",
    "6515bb1a838075da23be2bda",
    "6508758a7556c2e5db75b096",
    "655f7af058c548552e7df412",
    "6508800d7556c2e5db75b78d",
    "655ecd3c58c548552e7625ec",
    "655f67a758c548552e79ea2b",
    "65087e707556c2e5db75b700",
    "65ad896bc8178fd604e5f629",
    "65a876a3c8178fd604bcb77e",
    "65ad8683c8178fd604e570c6",
    "655ff97e58c548552e86c0a8",
    "65664b15b7d785d3ba762cf1",
    "65adbaa9c8178fd604e9b9c3",
    "65adbb6ac8178fd604e9e75d",
    "65ad92d8c8178fd604e8364b",
    "655f989958c548552e7fefa7",
    "650878b57556c2e5db75b2a8",
    "655e682a58c548552e6d381d",
    "655e70c758c548552e6fd423",
    "655e798858c548552e70426b",
    "655e9dcc58c548552e7466e2",
    "655ea52658c548552e749e37",
    "655ea94558c548552e74b942",
    "655eae4c58c548552e750507",
    "655eafe158c548552e751d75",
    "655f623758c548552e78ec44",
    "65086b457556c2e5db75a29a",
    "650866597556c2e5db759d78",
    "65086ad97556c2e5db75a23e",
    "655fb63b58c548552e82c08b",
    "655fbd7858c548552e83136c",
    "655fbee458c548552e832eff",
    "655fc65958c548552e83ee5a",
    "655fce3b58c548552e850427",
    "65087b1c7556c2e5db75b533",
    "655fd2f158c548552e859b2f",
    "65611068e22486beedf6140c",
    "65035438badc531267ce55c7",
    "65b1b774c8178fd60420f830",
    "65666a6cb7d785d3ba79d957",
    "655f9f5158c548552e818e1f",
    "655fbf7e58c548552e8349c6",
    "655fc07e58c548552e83c8f3",
    "65675315b7d785d3ba7d06b1",
    "65676ff1b7d785d3ba7eefdb",
    "656760efb7d785d3ba7e1502",
    "65678989b7d785d3ba801b5d",
    "65678ae0b7d785d3ba8080aa",
    "65678b9eb7d785d3ba80a13a",
    "6503103abadc531267cdfab6",
    "6567998cb7d785d3ba81f95e",
    "6560dd98e22486beedf1748d",
    "6560decae22486beedf176bf",
    "6567b90eb7d785d3ba84db4a",
    "6567bec6b7d785d3ba85fef4",
    "650874a97556c2e5db75af87",
    "65035353badc531267ce51bb",
    "650d91a3838075da23bd0388",
    "6508812f7556c2e5db75b868",
    "65143613838075da23bde94b",
    "6501cbdabadc531267cdb9fb",
    "6508766f7556c2e5db75b109",
    "65713bee89231f88c3464fef",
    "65087bde7556c2e5db75b5c4",
    "64ff6f3f590095491a932c28",
    "64ff7164590095491a933247",
    "65143684838075da23bdea70",
    "665a04b5ecc1ef0aa9ce36eb",
    "665deef6ecc1ef0aa9cf8579",
    "665e63e6ecc1ef0aa9d2bf95",
    "665dff79ecc1ef0aa9d085a0",
    "665e6208ecc1ef0aa9d2bafa",
    "665fb504ecc1ef0aa9e012e4",
    "65f06fb9cec4c40b6846f344",
    "65695d83b7d785d3baa0e6e7",
    "6570d29f89231f88c337793c",
    "657171f189231f88c34de737",
    "6571736289231f88c34e32e4",
    "6571742989231f88c34e5955",
    "657175e689231f88c34e7fea",
    "6571772189231f88c34ecc95",
    "6571792f89231f88c34f404d",
    "65717ab489231f88c34f8d65",
    "65717c0d89231f88c34fff3c",
    "65717e1089231f88c3507266",
    "65303b26779575d19d08d9ad",
    "65303c6e779575d19d08e1b3",
    "65303d94779575d19d08e3d2",
    "654e7837451d7e555c9a8563",
    "654e7e5e451d7e555c9c57b5",
    "654e7b02451d7e555c9b8537",
    "6570a4a189231f88c3307e7c",
    "652d8e9b838075da23c581ad",
    "6571617789231f88c34b1177",
    "657163b789231f88c34b5dcb",
    "656fe91789231f88c328866b",
    "656feb7989231f88c328d1dc",
    "656fef9589231f88c328faaa",
    "656ff1d589231f88c32922d2",
    "6570d8b589231f88c3385977",
    "6571518189231f88c349410c",
    "6571524289231f88c349667b",
    "657153a289231f88c349b417",
    "65709a0f89231f88c32eec7b",
    "6570ae8c89231f88c3337f34",
    "66be3eb239261b501d3a4e08",
    "66be0a0439261b501d2ce4f3",
    "656ba70e89231f88c3f3ecdc",
    "656baf0389231f88c3f47fc7",
    "656bb3d889231f88c3f4a757",
    "656bb69e89231f88c3f4ef21",
    "66edd35039261b501d5828d1",
    "66e1ada539261b501d920b1b",
    "66e2017839261b501da065d1",
    "66e1afea39261b501d92ffa7",
    "66e306ce39261b501da9de0e",
    "66e4e7cf39261b501dcb0c42",
    "66eafdcd39261b501d105406",
    "66e4f69a39261b501dcbda57",
    "66e476db39261b501dc6c6f9",
    "66ea2d1139261b501dff4a35",
    "66ea302d39261b501dffc713",
    "66ec97c239261b501d2d876a",
    "66df0c8839261b501d4235fe",
    "66c6a00739261b501de0c450",
    "66c754ef39261b501df36ae1",
    "65660abdb7d785d3ba6c0923",
    "66e3245e39261b501dad85c0",
    "65bab359c8178fd6046dea34",
    "66fd75a639261b501d3c24eb",
    "6709865339261b501d0e30ca"
    
 
  ];

  const textBlockFullWidthContainerCtaIds = [
    "655672fe58c548552e367f41",
    "654d1104451d7e555c922252",
    "65a9529bc8178fd604c307e8",
    "654e7537451d7e555c99c2d2",  
    "65b1603ec8178fd6041b78d2",
    "66e849a439261b501dd2fe92",
    
    
  ];

  const textBlockFullWidthLeftContainerCtaIds = [
    "65b162aec8178fd6041c01b1", 
    
  ];

  const textBlockImageContainerCtaIds = [
    "651ab792838075da23be9ca3",
    "64ff2bc1590095491a929f86",
    "655fcb4258c548552e84e1b8", 
    "655cff7f58c548552e641ac4",
    "650205cdbadc531267cdd728",
    "655f6cf958c548552e7ae57d",
    "655e7b7258c548552e70a467",
    "65020207badc531267cdd61c",
    "65020508badc531267cdd6ca",
    "6502038fbadc531267cdd656",
    "6571700889231f88c34d9b43",
    "6571726b89231f88c34e0cce",
    "65717eb989231f88c350be5c",
    "657181f889231f88c3510d5d",
    "657182b889231f88c3515903",
    "6571835a89231f88c3517f13",
    "6570aaaf89231f88c3313619",
    
    
    
  ];

  const readCtaIds = [ 
    "64f73d1d95cb62ffb977e1a5",
    "6508861c838075da23bc3fd8",
    "64f7534295cb62ffb977eb47",
    "64f7537795cb62ffb977eb79",
    "6539690fcaf6418cb0f71ad2",
    "650495cd4c6a15d9553e39dc",
    "65049a174c6a15d9553e42af",
    "65452fc2b7a06f9fe86b12ab",
    "650885b0838075da23bc3f7e",
    "65088675838075da23bc4ca0",
    "655cd94658c548552e606bd4",
    "655d177358c548552e652a65",
    "654be9df451d7e555c8db63c",
    "65367e11caf6418cb0ed8e11", 
    "655f724658c548552e7c00ed",
    "655f73d758c548552e7c676e",  
    
    
  ];

if (textBlockContainerCtaIds.indexOf(ctaId) > -1)
  return (
    <TextBoxContainer
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );


  if (ctaId === "654d02cd451d7e555c90b138" ) 
  return (
    <TemplateStorybookConclusionFromThisPointOnSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "65ad8cbdc8178fd604e70053"  ) 
  return (
    <IfYouHaveUsedARangedWeaponOrItemCards
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (textBlockFullWidthContainerCtaIds.indexOf(ctaId) > -1)
  return (
    <TextBoxContainerFullWidth
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

  if (textBlockFullWidthLeftContainerCtaIds.indexOf(ctaId) > -1)
  return (
    <TextBoxContainerLeftFullWidth
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );


  if (["654d130b451d7e555c923a5a",].indexOf(ctaId) > -1)
  return (
    <ConclusionTextBlock
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );
    
if (ctaId === "655d28e458c548552e66cace" || 
    ctaId === "655d27f758c548552e66b19f"  ) 
  return (
    <TextWithBulletPoint
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "650849f37556c2e5db757f8c"  ) 
  return (
    <TemplateTheItemsAreWXYZ
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (textBlockImageContainerCtaIds.indexOf(ctaId) > -1)
  return (
    <TextBoxImageContainer
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (readCtaIds.indexOf(ctaId) > -1)
  return (
    <Read
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "6526a49c838075da23c1be9e" ) 
  return (
    <TableOfContentSectionPage
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

    
if (ctaId === "6526a900838075da23c1ce4a")
  return (
    <TableOfContentsItemPage
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );


  

if (ctaId === "64f0d1b917b7622c4d32617a" )
  return (
    <TextBookletBlack
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );


if (ctaId === "653037aa779575d19d08caa3" )
return (
  <TemplateInterludeTextBlack
    key={index}
    variables={variables}
    template={template}
    card={card}
    cards={cards}
    deckId={deckId}
    fullWidth={fullWidth}
    templateData={templateData}
    ctaId={ctaId}
    parentId={variable.uuid}
  />
);


if (ctaId === "653037a1779575d19d08ca90" ) 
return (
  <TemplateInterludeTimeheaderVersion
    key={index}
    variables={variables}
    template={template}
    card={card}
    cards={cards}
    deckId={deckId}
    fullWidth={fullWidth}
    templateData={templateData}
    ctaId={ctaId}
    parentId={variable.uuid}
  />
);

if (ctaId === "656608ccb7d785d3ba6b8ee6" )
  return (
    <TemplateInterludeSubSection
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );


if (
    [
      "656f924089231f88c32184e1",
      "64f1eacfac6b165cf86e09ab",
      "652d6c12838075da23c4d246",
      "652d6e07838075da23c4d454",
      "652d8759838075da23c52f5a",
      "652d87dc838075da23c536c3",
      "65b41323c8178fd60440382c",
      "65553976451d7e555cc6bb4c",
      "65690ab4b7d785d3ba9e91c0",
      "65690bbcb7d785d3ba9ed63c",
      "66d0d1bf39261b501d9525f0",
      "65f0708ecec4c40b68475751",
      
      
       
    ].indexOf(ctaId) > -1
  )
  return (
    <TextBookletGeneral
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      noStyle={noStyle}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );


  if (
    [
      "6531370b779575d19d09a8d0", 
      "6531393d779575d19d09b63f", 
      "65314033779575d19d09eab9",
      "65bad4fcc8178fd60470d98b",
      
    ].indexOf(ctaId) > -1
  )
  return (
    <TextBookletGeneralV2
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      noStyle={noStyle}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "64f7527a95cb62ffb977e9ed" )
  return (
    <TextOnlyModule
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "651c1cb5838075da23bec65d" ||
    ctaId === "654bf467451d7e555c8e05dd" )
  return (
    <RuleTextColorBox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "65117caa838075da23bd3982" || 
    ctaId === "66b393d8ecc1ef0aa9269b08" ||
    ctaId === "65709b3889231f88c32f35aa"
  )
  return (
    <IfYouHaveDisciplineLevelXSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "655f9a2d58c548552e8056f5" || 
    ctaId === "65713a6689231f88c3460412" )
  return (
    <IfYouHaveRangedWeaponItemCardAndEitherDisciplineLevelX
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "65bc3ea8c8178fd60474441c"  )
  return (
    <IfYouHaveDisciplineLevelXSandboxV2
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

  if (ctaId === "665df337ecc1ef0aa9cfb8c0" )
    return (
      <IfYouHaveDisciplineLevelXSandboxV3
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

if ([
  "6516efcd838075da23be546b", 
  "654141e5b7a06f9fe85fdeb8", 
  "6569148cb7d785d3ba9f94dc", 
  "65691563b7d785d3ba9f96d6",
  "65b45ddcc8178fd6044325a3",
  "65691811b7d785d3baa0214a",
  "667ae0b7ecc1ef0aa94cee8d",
  "6570c72b89231f88c3362626",
  "6554faed451d7e555cc1f3a6",
  "66abbbc0ecc1ef0aa9a884da",
  "656a514589231f88c3f10e33",
  "6569f24a89231f88c3d7a6f0", 
  
].indexOf(ctaId) > -1)
  return (
    <TextBoxContainer
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid} 
    />
  );


  if ([

    "656df25489231f88c303d388", 
    "656a507189231f88c3f0e8aa",  //BOXE TEXTE AVEC BARRE EN BAS
    "656903c4b7d785d3ba9d7cb4",
  ].indexOf(ctaId) > -1)
    return (
      <StorybookConclusion
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid} 
      />
    );
  

if (ctaId === "65118b07838075da23bd40da") 
  return (
    <MakeSkillChecksBelowCanBeAttemptedOnlyOnceSandboxBigBox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

  if (ctaId === "6571508f89231f88c3491b82" || 
      ctaId === "6570de2389231f88c339624f" 
    
    
    )
  return (
    <IfYouWinAnYouUsedAnyOfTheFollowingItenCards
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );


if (ctaId === "6515be33838075da23be2c05" )
  return (
    <MakeAttributeSkillCheckDifficultyX
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "656755ffb7d785d3ba7d4749" )
  return (
    <MakeAAttributeSkillCheckDifficultyXWithRuleGrayBox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );


  if (ctaId === "66ec994739261b501d2dc767" ) 
  return (
    <MustEachMakeAttributeSkillCheckDifficultyX
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "6556ab6358c548552e3d62fb" )
  return (
    <YouMustEachMakeAttributeSkillSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );


  if (ctaId === "66ea2e1939261b501dff887f" ) 
  return (
    <YouMustEachMakeAttributeSkillCheck
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "654d4baf451d7e555c93e6a3" ||
    ctaId === "654d62af451d7e555c9443df" || 
    ctaId === "6571352b89231f88c3452314" || 
    ctaId === "66ea24fc39261b501dff0458")
  return (
    <YouMustEachMakeAAttributeSkillCheckDifficultyXSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );


if (ctaId === "6567c7cab7d785d3ba86a0fe")
  return (
    <MustMakeAttributeSkillCheckDifficultyXLongVersion
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "65578ccf58c548552e40663f"   ) 
  return (
    <YouMustEachMakeAttributeSkillCheckTableDifficulty
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );


if (ctaId === "654d4057451d7e555c93a4b5")
return (
  <StorybookConclusionNoRecoveryBetweenChapters
    key={index}
    variables={variables}
    template={template}
    card={card}
    cards={cards}
    deckId={deckId}
    fullWidth={fullWidth}
    templateData={templateData}
    ctaId={ctaId}
    parentId={variable.uuid}
  />
);

if ( ctaId === "665f52adecc1ef0aa9d737a1")
return (
 <XxxStorybookConclusionNoDiscardClueTokens
   key={index}
   variables={variables}
   template={template}
   card={card}
   cards={cards}
   deckId={deckId}
   fullWidth={fullWidth}
   templateData={templateData}
   ctaId={ctaId}
   parentId={variable.uuid}
 />
);


if (ctaId === "6565fb71b7d785d3ba694018"  ) 
return (
  <TemplateStorybookConclusion
    key={index}
    variables={variables}
    template={template}
    card={card}
    cards={cards}
    deckId={deckId}
    fullWidth={fullWidth}
    templateData={templateData}
    ctaId={ctaId}
    parentId={variable.uuid}
  />
);




if (ctaId === "655685f258c548552e37717f" || 
    ctaId === "656615b0b7d785d3ba6e672c" || 
    ctaId === "656626e6b7d785d3ba6fef70" ||
    ctaId === "656631ddb7d785d3ba728660" )
  return (
    <IfYouHaveDisciplineLevel3Sandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "655cdc4658c548552e60e664" || 
    ctaId === "655d0f5358c548552e64f70e" ||
    ctaId === "66e44eb439261b501db6eb25" )
  return (
    <IfYouHaveDiscipline1OrDiscipline2LevelX
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "655d32df58c548552e678edb"  ) 
  return (
    <IfYouHaveDisciplineLevelXAndThereAreNoOtherNpcs
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );


if (ctaId === "65030f75badc531267cdf997" )
  return (
    <CombatBeginsGoldtext
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "651175cf838075da23bd33f9" ) 
  return (
    <IfYouHaveClueTokenXSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "651ab5cd838075da23be9b25" )
  return (
    <IfYourCharacterHasDamageSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "650d8d44838075da23bcfa50")
  return (
    <IfYouWinLoseSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "64ff6d95590095491a9328d5" )
  return (
    <IfYouHavetheXItemCardChapterFailBlackboxMultitext
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "64ff6d98590095491a9328dc")
  return (
    <IfYouLoseYouFailBlackboxMultitext
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

  if (["665e6583ecc1ef0aa9d2f483",].includes(ctaId))
    return (
      <IfYouHaveClueTokenXAndActionTokenXIsNotOnTheTileChapterFailBlackbox
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

if (ctaId === "65a6e4bdc8178fd604a65b90" ||
    ctaId === "65a6f632c8178fd604a73afb")
  return (
    <HavetheEffectCardChapterFailBlackboxMultitext
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "651ab86e838075da23be9cea" ||
    ctaId === "651ab8eb838075da23be9d31" ||
    ctaId === "659c9724c8178fd6047ccb91" ||
    ctaId === "65132fe9838075da23bdd030" ||
    ctaId === "65088c92838075da23bc51cd" ||
    ctaId === "6539546acaf6418cb0f66478" ||
    ctaId === "651317e4838075da23bdbb2a" ||
    ctaId === "65088afc838075da23bc50dc" ||
    ctaId === "6515b8ea838075da23be29b5" ||
    ctaId === "65133099838075da23bdd16f" ||
    ctaId === "65133faa838075da23bddd62" ||
    ctaId === "650d9349838075da23bd05c4" ||
    ctaId === "654a6294b7a06f9fe8773fdf" ||
    ctaId === "650d9d6e838075da23bd14f2" ||
    ctaId === "65088890838075da23bc4e0c" ||
    ctaId === "654d45b2451d7e555c93cdfb" ||
    ctaId === "654d2e8a451d7e555c9309cc" ||
    ctaId === "6513226b838075da23bdcb66" ||
    ctaId === "655282ee451d7e555cb258b5" ||
    ctaId === "651188a1838075da23bd3e38" ||
    ctaId === "6552a308451d7e555cb4fb2c" ||
    ctaId === "655b9c7658c548552e5712b8" ||
    ctaId === "655662d558c548552e3532bf" ||
    ctaId === "6556668c58c548552e35c7fb" ||
    ctaId === "6556582b58c548552e336441" ||
    ctaId === "6556621858c548552e350bef" ||
    ctaId === "65569da058c548552e3cdb3e" || 
    ctaId === "6556748258c548552e36caa8" || 
    ctaId === "655e7ad958c548552e70a333" || 
    ctaId === "655cd8f658c548552e603f76" || 
    ctaId === "655ce3d958c548552e617473" || 
    ctaId === "655bdc1058c548552e5c87f9" || 
    ctaId === "655d341358c548552e67bdb5" || 
    ctaId === "655d351958c548552e67be5d" || 
    ctaId === "6557b72d58c548552e446ca4" || 
    ctaId === "65661a5fb7d785d3ba6f0fff" || 
    ctaId === "65666748b7d785d3ba797b46" || 
    ctaId === "65ad9ae3c8178fd604e866b4" || 
    ctaId === "651447b0838075da23bdf4d9" || 
    ctaId === "655e71e558c548552e6fff23" || 
    ctaId === "655e74df58c548552e701fad" ||
    ctaId === "655e76d658c548552e70246a" || 
    ctaId === "65020799badc531267cdd771" || 
    ctaId === "650d940f838075da23bd0647" || 
    ctaId === "65b1ba93c8178fd604218481" || 
    ctaId === "6567eb93b7d785d3ba876549" || 
    ctaId === "65117b3a838075da23bd37f8" || 
    ctaId === "65713d8a89231f88c346bf84" ||
    ctaId === "65713df089231f88c346e47f" ||
    ctaId === "65713e4689231f88c3472f45" ||
    ctaId === "650c45c9838075da23bc9afd" || 
    ctaId === "65713ffc89231f88c3477b18" ||
    ctaId === "650d8cf3838075da23bcfa1e" ||
    ctaId === "667b4024ecc1ef0aa954369c" ||
    ctaId === "667b8b2fecc1ef0aa957993a" ||
    ctaId === "667b8d86ecc1ef0aa9580690" ||
    ctaId === "66b5001aecc1ef0aa94ff3be" ||
    ctaId === "66c8ef9f39261b501d065d35" 
    
    
  )
  return (
    <IfYouFailSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

  if (ctaId === "663b9c96b95fd0ec1c68a9fd" ) 
    return (
      <MakeThe5SkillChecksBelowEachCheckCanBeAttemptedOnlyOnce
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );
  

if (ctaId === "650d9118838075da23bd0205" ||
    ctaId === "6570becb89231f88c334a963" ||
    ctaId === "6570bfbd89231f88c334d048") 
  return (
    <IfYouDoNotHaveClueTokenXSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "65a6e2f0c8178fd604a5d90d" || 
    ctaId === "656103dbe22486beedf3a3a1" || 
    ctaId === "6571304989231f88c3448801" )
  return (
    <IfYouHaveDisciplineLevelXDoNotHaveEffectCardSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "6553a252451d7e555cb86de7" ||
    ctaId === "65564a9958c548552e32132f" ||
    ctaId === "65552855451d7e555cc54c4f" || 
    ctaId === "6556642058c548552e35699d" 
  )
  return (
    <IfYouHaveClueTokenXAndYouHaveDisciplineXLevelXSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "65117e77838075da23bd3c34" ||
    ctaId === "65529dba451d7e555cb4a774" )
  return (
    <IfYouHaveXHungerSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "65493ca4b7a06f9fe8732e3e" )
  return (
    <IfYouHaveTheSkillLevelXSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "651c51c0838075da23bee1d9" )
  return (
    <MakeARouseCheckSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "655f5d4a58c548552e78d14d" ) 
  return (
    <MakeARouseCheckWithBoxesSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "655e96b958c548552e73eb07" )
  return (
    <MakeARouseCheck
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "650453bbbadc531267cee6ae"  )
  return (
    <MakeAttributeSkillCheckBlackbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (ctaId === "65b13a86c8178fd60418bfb6" )
  return (
    <RuleTextColorBox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );


if (
    [
      "651ac830838075da23bea2cc", 
      "65117e04838075da23bd3bc1",
      "65117d59838075da23bd3afe",
      "65b2a8b8c8178fd60432cdd9",
      "65131b55838075da23bdc16a",
      "650d9d20838075da23bd144f",
      "6554f4ec451d7e555cc0d1d7",
      "656602dab7d785d3ba6ad22b",
      "6554f544451d7e555cc0e44e",
      "650d9c1e838075da23bd0f95",
      "6597300be5509159c8c4f0ef",
      "65133238838075da23bdd4d4",
      "65133125838075da23bdd2ed",
      "651331f0838075da23bdd44e",
      "65537e3f451d7e555cb6b4c3",
      "65ab0187c8178fd604ddc3a5",
      "65ab000bc8178fd604dd951a",
      "65132f2c838075da23bdcf83",
      "6567e632b7d785d3ba8742f5",
      "655cdeea58c548552e615a45", 
      "65117cf1838075da23bd39cd",
      "655bda5558c548552e5c86b0",
      "655ec1f758c548552e75730e",
      "65088a27838075da23bc501b",
      "651189ee838075da23bd3f5d",
      "655d21bb58c548552e660422",
      "655d234f58c548552e6633cd",
      "655d2f0758c548552e677200",
      "655d2fa358c548552e6772c2",
      "655d31ca58c548552e678de7",
      "655d326b58c548552e678e5b",
      "6511709e838075da23bd30ce",
      "65662299b7d785d3ba6fadbd",
      "656632a7b7d785d3ba735425",
      "655f6e2458c548552e7ae7e9",
      "65b03c2ac8178fd6040d9e19",
      "655ba3cc58c548552e5779d0",
      "655ba93e58c548552e58035e",
      "655cb1f458c548552e5e7a2d",
      "65118a2b838075da23bd3fad",
      "655ecd8858c548552e762616",
      "655f693658c548552e7a1db6",
      "65ada3e2c8178fd604e8a284",
      "65ad8a7fc8178fd604e6a686",
      "650d9554838075da23bd0b7c",
      "650d8d6e838075da23bcfa7d",
      "650d9464838075da23bd0a37",
      "655f6e3c58c548552e7ae8c5",
      "651183e2838075da23bd3dc1",
      "650c4734838075da23bc9b7a",
      "650c4795838075da23bc9bd7",
      "650d93d7838075da23bd0611",
      "655f906158c548552e7f4f8d",
      "65676d4eb7d785d3ba7ebc0c",
      "6567755eb7d785d3ba7f5b4a",
      "65679057b7d785d3ba81282e",
      "65117574838075da23bd33ba",
      "650d8fd2838075da23bcffbf",
      "65691639b7d785d3ba9f9a60",
      "65695bc4b7d785d3baa0c44e",
      "65716e2689231f88c34d292c",
      "65b40d61c8178fd6043ddd23",
      "657177f289231f88c34f1844",
      "65717b5489231f88c34fb3b9",
      "65717d8489231f88c3504c78",
      "655b987558c548552e56478b",
      "65715fa089231f88c34ae9af",
      "6571696f89231f88c34c8ec5",
      "656ff52989231f88c329b6da",
      "6570d60989231f88c337eaa3",
      "66bb6f6b39261b501dfbbf38",
      "6573352689231f88c3585c2c",
      "657373ed89231f88c36898b9",
      "6573783089231f88c369aaff",
      "656b9fbc89231f88c3f334a9",
      "656ba55889231f88c3f38257",
      "66e214c839261b501da2988a",
      "66c132c039261b501d81b3a3",
      "66c1345c39261b501d834b9b",
      
    ].indexOf(ctaId) > -1
  )
  return (  
    <TextBoxModuleContainer
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (
    ["6511773f838075da23bd34b5",  ].indexOf(ctaId) > -1
   )
  return (
    <IfYouHaveDisciplineLevelXOrTheSkillLevelYSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (
    ["65667318b7d785d3ba7a18ef", ].indexOf(ctaId) > -1
   )
  return (
    <YouDoNotHaveClueTokenXAndNpcOnTileSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );


if (
    ["6556a24958c548552e3ceec1",].indexOf(ctaId) > -1
   )//texte
  return (
    <EachCharacterWithoutatLeastXMustTakeTheEffectCard
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if ([
      "65b13931c8178fd60418915c",
      "65b0a559c8178fd6040f18af", 
    ].indexOf(ctaId) > -1)
return (
  <IfYouHaveTheFollowingEffectCards
    key={index}
    variables={variables}
    template={template}
    card={card}
    cards={cards}
    deckId={deckId}
    fullWidth={fullWidth}
    templateData={templateData}
    ctaId={ctaId}
    parentId={variable.uuid}
  />
);
 
if (["65030e56badc531267cdf843", ].indexOf(ctaId) > -1) 
return (
  <RollaRedDieSandbox
    key={index}
    variables={variables}
    template={template}
    card={card}
    cards={cards}
    deckId={deckId}
    fullWidth={fullWidth}
    templateData={templateData}
    ctaId={ctaId}
    parentId={variable.uuid}
  />
);

if (["6556a97858c548552e3d2870",].indexOf(ctaId) > -1)
return (
  <RollARedDieFailOnlySandbox
    key={index}
    variables={variables}
    template={template}
    card={card}
    cards={cards}
    deckId={deckId}
    fullWidth={fullWidth}
    templateData={templateData}
    ctaId={ctaId}
    parentId={variable.uuid}
  />
);

if (
  ["65a61953c8178fd604a00af3", ].indexOf(ctaId) > -1
)
return (
  <TemplateRollaRedDieSuccessOnlySandbox
    key={index}
    variables={variables}
    template={template}
    card={card}
    cards={cards}
    deckId={deckId}
    fullWidth={fullWidth}
    templateData={templateData}
    ctaId={ctaId}
    parentId={variable.uuid}
  />
);

if (
  [
    "657b6d6289231f88c3d331dd", 
    "6567bfdeb7d785d3ba865dad",   //titre | logo + success | boxe dorée || logo + faillure || boxe chap26p60
  ].indexOf(ctaId) > -1
)
  return (
    <TemplateMakARouseCheckForEachDifficult
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

  if (
    [
      "6571677689231f88c34bd1de",
    ].indexOf(ctaId) > -1
  )// check check text read | check false text read | false false text read - module lassombra p74
    return (
      <MakeRouseChecks
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );
    


  if (
    [
      "655fca4b58c548552e84dce9",  
      "657141ee89231f88c347eb4b", //titre | logo + failure | boxe 
      
    ].indexOf(ctaId) > -1
  )
    return (
      <EachPlayerMustMakeRouseCheckFailureOnly
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );


  if (
    //READ
    // Texte marron ou un Texte noir | liens || you must read event
    [
      "65088947838075da23bc4eb8",
      "651174fb838075da23bd330b",
      "6512fa31838075da23bda24d",
      "667b1baeecc1ef0aa94ea7de",
      "6543954eb7a06f9fe86238e0",
      "65395884caf6418cb0f68e2a",
      "65088bf1838075da23bc5152",
      "65381a75caf6418cb0f503d6",
      "654e8794451d7e555c9da69e",
      "654e8613451d7e555c9d598a",
      "654e85db451d7e555c9d4abe",
      "65a620c8c8178fd604a0e409",
      "65660938b7d785d3ba6bc954",
      "650d95cd838075da23bd0c09",
      "65ab17a5c8178fd604e27d0d",
      "65ab170fc8178fd604e224f3",
      "65566b6d58c548552e361874",
      "655cda5e58c548552e609ba7",
      "6567cb5bb7d785d3ba86e043",  
      "655ecbf458c548552e760cdf",
      "655ce2ab58c548552e61739d",  
      "655ce4d458c548552e618c21", 
      "655ec2dd58c548552e7573d3",
      "655ec44e58c548552e75a43c",
      "655ec97b58c548552e75db2b", 
      "655e28d158c548552e690971",
      "65660546b7d785d3ba6b2e23",
      "656629deb7d785d3ba702e29",
      "655badd458c548552e58679c",
      "656fe84d89231f88c32861d6",
      "6560d93ee22486beedf121ef",
      "65664c3ab7d785d3ba766c30",
      "65675be6b7d785d3ba7d8ff4",
      "656028b258c548552e87b4a6",
      "65691706b7d785d3ba9fbd1a",
      "65716edf89231f88c34d4f75",
      "66904641ecc1ef0aa9fb27b3",
      "668ec44fecc1ef0aa9d395f0",
      "66a7d2bcecc1ef0aa981e617",
      "66aff8d2ecc1ef0aa9dc147d",
      "65b85a5dc8178fd6045a6440",
      "653147cc779575d19d0a1762",
      "6570d3d889231f88c337c3af",
      "66bf744139261b501d6c1630",
      "66be0eb539261b501d2d912e",
      "657333bd89231f88c3583400",
      "656a484789231f88c3ef529a",
      "656a497f89231f88c3ef9bca", 
      "66e30a2339261b501daa5987",
      "66eb64d039261b501d1a2203",
      "66ec939d39261b501d2c87fe",
      "66ccfc2839261b501d48ae7e",
      "66cd110c39261b501d4a1c4e",
      "66cd233b39261b501d4ad44a",
    ].indexOf(ctaId) > -1
  )
    return (
      <TextBoxModuleContainerV2
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

    if (
      //READ
      // Texte marron ou un Texte noir | liens || you must read event
      [
        "6561103ce22486beedf5f9ee",
      
      ].indexOf(ctaId) > -1
    )
      return (
        <TextBoxModuleContainerV3
          key={index}
          variables={variables}
          template={template}
          card={card}
          cards={cards}
          deckId={deckId}
          fullWidth={fullWidth}
          templateData={templateData}
          ctaId={ctaId}
          parentId={variable.uuid}
        />
      );


    //box Win + read liens module |  Lose + read liens module 
    if (
      [
        "655ce91058c548552e618f91",
      ].indexOf(ctaId) > -1
    )
      return (
        <IfYouWinOrIfYouLoseSandbox
          key={index}
          variables={variables}
          template={template}
          card={card}
          cards={cards}
          deckId={deckId}
          fullWidth={fullWidth}
          templateData={templateData}
          ctaId={ctaId}
          parentId={variable.uuid}
        />
      );


    if (
      // Texte marron ou un Texte noir | liens
      [
        "6556706958c548552e366841",
        
      ].indexOf(ctaId) > -1
    )
      return (
        <IfAnotherCharacterEndsTheirTurnOnAnHexAdjacentToYou
          key={index}
          variables={variables}
          template={template}
          card={card}
          cards={cards}
          deckId={deckId}
          fullWidth={fullWidth}
          templateData={templateData}
          ctaId={ctaId}
          parentId={variable.uuid}
        />
      );
  

  if (ctaId === "652556c2838075da23c12b6c"  )
    return (
      <SetupImageHexGrid
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
      />
    );

  if (["65117115838075da23bd312d", ].indexOf(ctaId) > -1)
    return (
      <IfYouHaveDisciplineSandbox
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

  if (["65117645838075da23bd3447", 
       "656758b9b7d785d3ba7d6be2",
 
       
      ].indexOf(ctaId) > -1)
    return (
      <IfYouHaveTheXItemCardSandbox
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

  if (["6512eef2838075da23bd988f",  ].indexOf(ctaId) > -1)
    return (
      <IfNpc1Npc2Npc3AreOnTheTile
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );


    if (["657154cc89231f88c349dae1",].indexOf(ctaId) > -1)
      return (
        <IfYouWantToLeaveYouCanDoSo
          key={index}
          variables={variables}
          template={template}
          card={card}
          cards={cards}
          deckId={deckId}
          fullWidth={fullWidth}
          templateData={templateData}
          ctaId={ctaId}
          parentId={variable.uuid}
        />
      );

  if (["6542bceab7a06f9fe861e467",].indexOf(ctaId) > -1)
    return (
      <StaticMakeAAttributeSkillCheckWith2OutcomesBlackbox
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

  if (["65257262838075da23c17013", ].indexOf(ctaId) > -1)
    return (
      <GangrelRuleBoxWithTable
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

  if (["6549524bb7a06f9fe873d03c",  ].indexOf(ctaId) > -1)
    // liens
    return (
      <MakeAAttributeSkillCheckDifficultyXLongVersionSandbox
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

  if (["654a8ecdb7a06f9fe8797170",].indexOf(ctaId) > -1)
    return (
      <StaticMakeAttributeSkillCheckDifficultyXSandbox
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

  if (["652d61a2838075da23c496c3" , ].indexOf(ctaId) > -1)
    return (
      <SetupNpcToken
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

    if (["6554fb01451d7e555cc1f3d2", ].indexOf(ctaId) > -1)
      return (
        <PatrolToken
          key={index}
          variables={variables}
          template={template}
          card={card}
          cards={cards}
          deckId={deckId}
          fullWidth={fullWidth}
          templateData={templateData}
          ctaId={ctaId}
          parentId={variable.uuid}
        />
      );

      if (["6554fb1e451d7e555cc1f410",].indexOf(ctaId) > -1)
        return (
          <PatrolArrowRight
            key={index}
            variables={variables}
            template={template}
            card={card}
            cards={cards}
            deckId={deckId}
            fullWidth={fullWidth}
            templateData={templateData}
            ctaId={ctaId}
            parentId={variable.uuid}
          />
        );


  if ([
        "6531932f779575d19d0b975c", 
        "654d448a451d7e555c93bcdb", 
        "654d4500451d7e555c93be4d",
        
      ].indexOf(ctaId) > -1)
  return (
    <SetupNpcTokenV2
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );




if (["653191d6779575d19d0b972c",
      "65381e28caf6418cb0f51cd4", 
      "652d5f80838075da23c4946d",
      "65f06576cec4c40b6845aae3",
      "65665da2b7d785d3ba78be5d",

    ].indexOf(ctaId) > -1)
return (
  <TemplateSetupPreyNpcSection //a voir "6706bf8139261b501de796d4"
    key={index}
    variables={variables}
    template={template}
    card={card}
    cards={cards}
    deckId={deckId}
    fullWidth={fullWidth}
    templateData={templateData}
    ctaId={ctaId}
    parentId={variable.uuid}
  />
);


if (["6706bf8139261b501de796d4",
    "6706b71639261b501de32516",
    "6706b84139261b501de369ca",
    "6706c12339261b501de96645",
    "6706bde739261b501de7121d"
].indexOf(ctaId) > -1)
return (
<XxxRuleGrayChap14Restart
variables={variables}
template={template}
card={card}
cards={cards}
deckId={deckId}
fullWidth={fullWidth}
templateData={templateData}
ctaId={ctaId}
parentId={variable.uuid}
/>
);





if ([
  "66c3d40939261b501da5d1cc",
].indexOf(ctaId) > -1)
return (
<TemplateTextGeneral
key={index}
variables={variables}
template={template}
card={card}
cards={cards}
deckId={deckId}
fullWidth={fullWidth}
templateData={templateData}
ctaId={ctaId}
parentId={variable.uuid}
/>
);

if ([
  "66c3d46a39261b501da5d30b",
  
].indexOf(ctaId) > -1)
return (
<TemplateTextGeneral2
key={index}
variables={variables}
template={template}
card={card}
cards={cards}
deckId={deckId}
fullWidth={fullWidth}
templateData={templateData}
ctaId={ctaId}
parentId={variable.uuid}
/>
);

if (["652d90a0838075da23c58699",].indexOf(ctaId) > -1)
return (
  <TemplateSetupPreyNpcSectionV2
    key={index}
    variables={variables}
    template={template}
    card={card}
    cards={cards}
    deckId={deckId}
    fullWidth={fullWidth}
    templateData={templateData}
    ctaId={ctaId}
    parentId={variable.uuid}
  />
);


if (["652d91ce838075da23c58fb1",].indexOf(ctaId) > -1)
return (
  <SetupPlayerToken
    key={index}
    variables={variables}
    template={template}
    card={card}
    cards={cards}
    deckId={deckId}
    fullWidth={fullWidth}
    templateData={templateData}
    ctaId={ctaId}
    parentId={variable.uuid}
  />
);

if (["652d5f36838075da23c48d42", 
     "6537d047caf6418cb0f1d56d", 
    ].indexOf(ctaId) > -1) 
  return (
    <SetupNpcColumn
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
    />
  );

  //Box avec texte + liens + logo
if (//verifier couleur ex chap26p16
  [
    "650887a6838075da23bc4d43",
    "65117a25838075da23bd36fa",
    "65526398451d7e555caf7c86",
    "65527740451d7e555cb14609",
    "6553a2ac451d7e555cb88e35",
    "65666621b7d785d3ba797a61",
    "65711e7b89231f88c3435e06",
    "65b1b6aac8178fd60420c9c7",
    "65664f25b7d785d3ba76db1e",
    "656669ccb7d785d3ba79d8d7",
    "66bf76e439261b501d6cc5a4",
    "66ccbc3239261b501d2c7530",

    
 
  ].indexOf(ctaId) > -1
)
  return (
    <IfYouAreCharacterSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );


    //box texte plus logo conclusion
if (
[
  "654d0dea451d7e555c916c1b",


].indexOf(ctaId) > -1
)
return (
  <TemplateStorybookConclusionExpansionAvailable
    key={index}
    variables={variables}
    template={template}
    card={card}
    cards={cards}
    deckId={deckId}
    fullWidth={fullWidth}
    templateData={templateData}
    ctaId={ctaId}
    parentId={variable.uuid}
  />
);

if (
  [
    "654d03cf451d7e555c90b23c",
    "66c4e2d439261b501dc233e9",

  ].indexOf(ctaId) > -1
  )
  return (
    <TemplateStorybookConclusionSideQuestAvailable
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

  if (
    [
      "66df568c39261b501d60525f",
    ].indexOf(ctaId) > -1
    )
    return (
      <TemplateStorybookConclusionNoDiscardClueTokens
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );


  if (
    [
      "65690da3b7d785d3ba9f2835", 
    ].indexOf(ctaId) > -1
    )
    return (
      <StorybookIntroTextCalebLetter
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );
  

  //Box avec texte + liens + logo v5
if (["65564e7258c548552e3229a0",   

    ].indexOf(ctaId) > -1) 
  return (
    <IfYouAreCharacterAndYouHaveItemCardSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

  //texte + logo | mini texte | liens
  if (
    [
      "6552af8a451d7e555cb56410", //chapitre 12 p186
      "6595c402e5509159c8a4148e", //chapitre 13 p70

    ].indexOf(ctaId) > -1
  )
    return (
      <IfYouAreCharacterIsNotActiveSandbox
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

    if (
      [
        "6571571789231f88c34a27e4", //chapitre 12 p186
      ].indexOf(ctaId) > -1
    )
      return (
        <IfYouAreLuciannaRicciOrJadeLinhDuplessis
          key={index}
          variables={variables}
          template={template}
          card={card}
          cards={cards}
          deckId={deckId}
          fullWidth={fullWidth}
          templateData={templateData}
          ctaId={ctaId}
          parentId={variable.uuid}
        />
      );

  if (
    [
      //Titre + sous titre + liens + logo x2 + logo x3
      "650887f3838075da23bc4d85",
      "653ab2f8caf6418cb0fa4656",
      "65957dbfe5509159c8a209f6",
      "65553b73451d7e555cc6f1dc",
      "6567d2abb7d785d3ba86e2c1",
      "655ec6d358c548552e75be30",//logo x3 chap20p120 
 
    ].indexOf(ctaId) > -1
  )
    return (
      <IfYouAreSamuelArmstrongOrHaroldBeaulieuSandbox
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

    if (
      [
        //Titre + sous titre + liens + logo x2
        "655bb02358c548552e58b1cd",
      ].indexOf(ctaId) > -1
    )
      return (
        <IfYouAreArenKonwayOrThomasChartrand
          key={index}
          variables={variables}
          template={template}
          card={card}
          cards={cards}
          deckId={deckId}
          fullWidth={fullWidth}
          templateData={templateData}
          ctaId={ctaId}
          parentId={variable.uuid}
        />
      );

    if (
      [
        //Titre + sous titre + liens + logo x2 v2
        "655cc31258c548552e5eee2f",

      ].indexOf(ctaId) > -1
    )
      return (
        <IfBothCharacterAreInvolvedInTheSequence
          key={index}
          variables={variables}
          template={template}
          card={card}
          cards={cards}
          deckId={deckId}
          fullWidth={fullWidth}
          templateData={templateData}
          ctaId={ctaId}
          parentId={variable.uuid}
        />
      );




  if (["65116e58838075da23bd2cb7",
      ].indexOf(ctaId) > -1)
    return (
      <IfTheTrackertypeTrackerReachesX
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

  if (["64ff5b8c590095491a92f375",].indexOf(ctaId) > -1)
    return (
      <IfNpcisAdjacenttoYouChapterFailBlackboxMultitext
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

  if (["65527395451d7e555cb11527",].indexOf(ctaId) > -1)
    return (
      <IfYouAreTheOnlyPlayerOnTheTileYouFailBlackboxMultitext
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

  if (["654cd4ef451d7e555c8e7cb9", ].indexOf(ctaId) > -1)
    return (
      <IfThereAreNoPlayerOnTheTileYouFailBlackbox
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

  if (["656cd95489231f88c3f6e679", ].indexOf(ctaId) > -1)
    return (
      <GoldTextBox
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

    if (["655eb8c258c548552e752544",].indexOf(ctaId) > -1)
    return (
      <IfAllActionTokensHaveBeenRemovedFromTheTileChapterFail
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );


    if (["655e906158c548552e72fa50",
      
  
    ].indexOf(ctaId) > -1)
    return (
      <IfYouHaveTheXEffectCardChapterFailBlackbox
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );


    if (["651738a0838075da23be7bb2", 
        ].indexOf(ctaId) > -1)
    return (
      <IfAllActionTokensHaveBeenRemovedFromTheTileChapterFail
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );



  if (["656ce5e989231f88c3f75582",
       
      ].indexOf(ctaId) > -1)
    return (
      <FeDirective
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

  if (ctaId === "654e94e5451d7e555ca43834"   )
    return (
      <TemplateOtherwiseResumePlayingBlackText
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

  
    if (ctaId === "655b704958c548552e4f3075" )
    return (
      <OtherwiseResumePlayingCreamText
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

  if (
    ["656d09d489231f88c3feb4d0", 
     "656e264289231f88c30a75f8",  
      "665f7959ecc1ef0aa9dcd899",


    ].indexOf(ctaId) > -1
  )
    return (
      <TextBoxContainerContainer
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
        removeLink={removeLink}
      />
    );

    if (
      ["656111e9e22486beedf6d236",].indexOf(ctaId) > -1
    )
      return (
        <TextBoxBulletContainer
          key={index}
          variables={variables}
          template={template}
          card={card}
          cards={cards}
          deckId={deckId}
          fullWidth={true}
          templateData={templateData}
          ctaId={ctaId}
          parentId={variable.uuid}
        />
      );

  if (
    ["656e38e989231f88c30c0a90", 
      "656cf9ae89231f88c3f92b77", 
      
    ].indexOf(ctaId) > -1
  )
    return (
      <TextBoxContainerContainerContainer
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

  if (
    [
      "656cf7cf89231f88c3f9076f",
      "656cf96389231f88c3f92b46", 
    ].indexOf(ctaId) > -1
  )
    return (
      <TextBoxContainerContainerIconText
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

  if (
    [
      "65aad664c8178fd604d4ceb8", 

    ].indexOf(ctaId) > -1
  )
    return (
      <Chapter16TableBlackbox
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );


if (["656cf28289231f88c3f8da83", ].indexOf(ctaId) > -1)
  return (
    <FeedingEventPhysicalOrMentalResult
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      isPhysical={isPhysical}
    />
  );

  

if (["6556722f58c548552e367d37", ].indexOf(ctaId) > -1)
  return (
    <IfAnotherCharacterTurnOnAnHexAdjacentToYouSandbox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      isPhysical={isPhysical}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (
    [
      "655faf3b58c548552e8281bd", 
      "6560eb59e22486beedf2d1e3", 
      "656ff3b689231f88c3294932",
      "66bc25ea39261b501d07f9c1",
      "66ccfdae39261b501d49266d",
      
      
    ].indexOf(ctaId) > -1
   )
   return (
      <IfYouHaveUsedBiteCombatCardOrAnyDiscipline
        key={index}
        variables={variables}
        template={template}
        card={card}
        cards={cards}
        deckId={deckId}
        fullWidth={fullWidth}
        templateData={templateData}
        isPhysical={isPhysical}
        ctaId={ctaId}
        parentId={variable.uuid}
      />
    );

if (["650d9222838075da23bd04be",  ].indexOf(ctaId) > -1)
  return ( 
    < TemplateIfYouHadDiscardedWeaponsMultibox
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      isPhysical={isPhysical}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (["66437affb95fd0ec1c76d1fa", ].indexOf(ctaId) > -1)
  return ( 
    < TextWithModule
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      isPhysical={isPhysical}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );

if (["667b31e7ecc1ef0aa951399f", ].indexOf(ctaId) > -1)
  return ( 
    <IfNpc1IsOnTheTileStorybook
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      isPhysical={isPhysical}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );
if ([
"665f767decc1ef0aa9dc0960", 
"654e8648451d7e555c9d59d0", 
"665f7d36ecc1ef0aa9dd73b1", 
"665f7dc1ecc1ef0aa9dd7430",
"65303d30779575d19d08e2ec",
"654e7762451d7e555c9a679e",
"654e84b5451d7e555c9d1c0d",
"65660cdab7d785d3ba6c9fa4",
"66fb0b0739261b501d232607",
"66fabd2a39261b501d1d99c0"

].indexOf(ctaId) > -1)
return ( 
  <ImageInterlude
    key={index}
    variables={variables}
    template={template}
    card={card}
    cards={cards}
    deckId={deckId}
    fullWidth={fullWidth}
    templateData={templateData}
    isPhysical={isPhysical}
    ctaId={ctaId}
    parentId={variable.uuid}
  />
);
if (["653037df779575d19d08cae3", 
    "654e7b97451d7e555c9b94d0", 
    
  ].indexOf(ctaId) > -1)
return ( 
<InterludeBlackBoxContainer
key={index}
variables={variables}
template={template}
card={card}
cards={cards}
deckId={deckId}
fullWidth={fullWidth}
templateData={templateData}
isPhysical={isPhysical}
ctaId={ctaId}
parentId={variable.uuid}
/>
);

if ([
  "653037bd779575d19d08cabb", 
  "66df528839261b501d5d7c1b"].indexOf(ctaId) > -1)
return ( 
<TextOnlyBlock
  key={index}
  variables={variables}
  template={template}
  card={card}
  cards={cards}
  deckId={deckId}
  fullWidth={fullWidth}
  templateData={templateData}
  isPhysical={isPhysical}
  ctaId={ctaId}
  parentId={variable.uuid}
/>
);

if (["665f6dddecc1ef0aa9d99f08", ].indexOf(ctaId) > -1)
return ( 
  <TextBoxDoubleContainerContainerContainer
    key={index}
    variables={variables}
    template={template}
    card={card}
    cards={cards}
    deckId={deckId}
    fullWidth={fullWidth}
    templateData={templateData}
    isPhysical={isPhysical}
    ctaId={ctaId}
    parentId={variable.uuid}
  />
);

if (["654e761a451d7e555c99d383", 
     "654e76bb451d7e555c9a0105",
      
  ].indexOf(ctaId) > -1)
return ( 
  <InterludeSandboxSection
    key={index}
    variables={variables}
    template={template}
    card={card}
    cards={cards}
    deckId={deckId}
    fullWidth={fullWidth}
    templateData={templateData}
    isPhysical={isPhysical}
    ctaId={ctaId}
    parentId={variable.uuid}
  />
);

if (["654e872a451d7e555c9d79a6", 
    "66c3e61c39261b501da66042",
    
  
  ].indexOf(ctaId) > -1)
return ( 
  <InterludeSandboxSectionTriple
    key={index}
    variables={variables}
    template={template}
    card={card}
    cards={cards}
    deckId={deckId}
    fullWidth={fullWidth}
    templateData={templateData}
    isPhysical={isPhysical}
    ctaId={ctaId}
    parentId={variable.uuid}
  />
);


if (["66fc221939261b501d313fbe",
].indexOf(ctaId) > -1)
  return (
    <TemplatePatrolEllipsis
      key={index}
      variables={variables}
      template={template}
      card={card}
      cards={cards}
      deckId={deckId}
      fullWidth={fullWidth}
      templateData={templateData}
      ctaId={ctaId}
      parentId={variable.uuid}
    />
  );
   

  console.log("CTA NOT FOUND", ctaId);
  console.log("CTA NOT FOUND - template", template?.name);

  return null;
};

export default ModuleSelector;
